* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 16;
}

body,
html,
#root {
  display: flex;
  min-height: 100vh;
  height:100vh;
  max-height:100vh;
  width:100%;
  flex:1;
  overflow:hidden;
}


input:focus,textarea:focus{
  outline-style:none;
  box-shadow:none;
  outline:none;
  /* border-color:transparent; */
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


select{
  background: #fff;
  height: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}


.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  /* background-size: 800px 104px;  */
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}


@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.react-datepicker__input-container input{
  font-family: "Montserrat", sans-serif;
  font-size:16px;
  border-radius: 4px;
  padding: 10px;
  border: 0;
  font-size: 16px;
  text-align:center;
  border: 1px solid #EDEDED;
}
